export default [
  {
    key: "client",
    label: "Client",
    class: "text-left",
    visible: true,
  },
  {
    key: "account",
    label: "Account",
    class: "text-left",
    visible: true,
  },
  {
    key: "risky",
    label: "Risky Level",
    class: "text-centert",
    visible: true,
  },
  {
    key: "captured",
    label: "Captured by",
    class: "text-center",
    visible: true,
  },
  {
    key: "seller",
    label: "Seller by",
    class: "text-center",
    visible: true,
  },
  {
    key: "specialist",
    label: "Specialist",
    class: "text-center",
    visible: true,
  },
  {
    key: "q_date",
    label: "Qualification date",
    class: "text-center",
    visible: true,
  },
];
