<template>
  <div class="px-2">
    <header-slot />
    <filter-slot
      :filter="FiltersForSlot.filters"
      :total-rows="FiltersForSlot.totalRows"
      :paginate="FiltersForSlot.paginate"
      :start-page="FiltersForSlot.startPage"
      :to-page="FiltersForSlot.toPage"
      :filter-principal="FiltersForSlot.filterPrincipal"
      @reload="$refs['riskyClientsTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <b-table
        ref="riskyClientsTable"
        no-border-collapse
        slot="table"
        class="position-relative"
        :items="myProvider"
        :fields="Fields"
        :busy="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="FiltersForSlot.paginate.perPage"
        :current-page="FiltersForSlot.paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(client)="{ item }">
          <div class="text-primary">
            <router-link
              target="_blank"
              :to="`/quality/clients/account/${item.id_account}`"
            >
              {{ item.client }}
            </router-link>
            <b-badge
              class="font10 ml-1"
              :variant="item.client_type_id == 2 ? 'info' : 'primary'"
              v-if="[6, 20, 21, 22].includes(item.module_id)"
            >
              {{ item.client_type_id == 2 ? "Digital" : "Regular" }}
            </b-badge>
          </div>
          <div>{{ item.mobile }}</div>
          <div class="d-flex">
            <IconStatusAccount :status="item.status_account_id" fromBc />
            <p style="margin-left: 5px">{{ item.status_account_name }}</p>
          </div>
        </template>

        <template #cell(risky)="{ item }">
          <b-badge
            style="width: 90px"
            :variant="getRiskyBadgeVariant(item.level)"
          >
            <span>{{ getRiskyLevelName(item.level) }}</span>
          </b-badge>
        </template>

        <template #cell(q_date)="{ item }">
          {{ item.created_risk_at | myGlobal }}
        </template>
      </b-table>
    </filter-slot>
  </div>
</template>
<script>
// DATA
import Fields from "@/views/quality/views/risky-clients/data/fields.js";
import Filters from "@/views/quality/views/risky-clients/data/filters.js";

// COMPONENTS
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";

// SERVICE
import RiskyService from "@/views/management/views/risky-clients/risky.service";

// STORE
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    IconStatusAccount,
  },
  data() {
    return {
      items: [],
      Fields,
      FiltersForSlot: {
        filters: Filters,
        totalRows: 1,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 0,
        toPage: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name or account...",
          model: "",
        },
      },
      isBusy: false,
    };
  },
  props: {
    tabSelected: String,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      getRefreshTable: "QualityRiskyClientsStore/getRefreshTable",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "QualityRiskyClientsStore/CHANGE_REFRESH_TABLE",
    }),

    getFilterModelByKey(key) {
      const filter = this.FiltersForSlot.filters.find(
        (filter) => filter.key === `${key}`
      );

      return filter.model;
    },

    async myProvider(ctx) {
      try {
        const params = {
          per_page: ctx.perPage,
          orderby: 13,
          page: ctx.currentPage,
          order: "desc", // ctx.sortDesc == 1 ? "desc" : "asc",
          text: this.FiltersForSlot.filterPrincipal.model,
          from_risk: this.getFilterModelByKey("from"),
          to_risk: this.getFilterModelByKey("to"),
          client_type: this.getFilterModelByKey("client_type"),
          risk_level: this.getFilterModelByKey("risky_level"),
          captured: this.captured ?? null,
          seller: this.seller ?? null,
          type_risky: 1,
          type_risky_complete: 2,
          rolsession: this.currentUser.role_id,
          program: null,
        };

        const { data } = await RiskyService.getRiskyClients(params);
        this.items = data.data;

        this.FiltersForSlot.startPage = data.from;
        this.FiltersForSlot.paginate.currentPage = data.current_page;
        this.FiltersForSlot.paginate.perPage = data.per_page;
        this.FiltersForSlot.totalRows = data.total;
        this.FiltersForSlot.toPage = data.to;
        return this.items || [];
      } catch (ex) {
        return [];
      }
    },

    getRiskyBadgeVariant(level) {
      const variants = {
        1: "info",
        2: "warning",
        3: "danger",
      };
      return variants[level] || "N/A";
    },

    getRiskyLevelName(level) {
      const names = {
        1: "LOW",
        2: "MEDIUM",
        3: "HIGH",
      };
      return names[level] || "NO RISK";
    },

    resetAllFilters() {
      this.FiltersForSlot.filters.forEach((filter) => {
        filter.model = null;
      });
      this.FiltersForSlot.filterPrincipal.model = null;
      this.$refs.riskyClientsTable.refresh();
    },
  },
  watch: {
    getRefreshTable(value) {
      if (value) {
        this.$refs["riskyClientsTable"].refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
};
</script>
