const filters = [
    {
        key: "from",
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        nameFilter: 'from'
    },
    {
        key: "to",
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        nameFilter: 'to'
    },
    {
        key: "client_type",
        type: "select",
        margin: true,
        showLabel: true,
        label: "Client type",
        model: null,
        options: [
            { id: 1, text: "Regular" },
            { id: 2, text: "Digital" },
        ],
        reduce: "id",
        selectText: "text",
        cols: 6,
        visible: true,
    },
    {
        key: "risky_level",
        type: "select",
        margin: true,
        showLabel: true,
        label: "Risky level",
        model: null,
        options: [
            { id: 1, text: "Low" },
            { id: 2, text: "Medium" },
            { id: 3, text: "High" },
        ],
        reduce: "id",
        selectText: "text",
        cols: 6,
        visible: true,
    },
];

export default filters;